/*Cards Styles*/

.profile-intro {
    background-color: $white;
    @include border-radius($border-radius !important);
    overflow: hidden;
    &:hover {
        @include box-shadow($shadow-lg);
        & .avatar-circle {
            @include transition(border-radius 0.4s ease 0.1s);
            @include border-radius(25% !important);
        }
    }
    img.avatar-circle {
        display: inline-block;
        @extend %size-120;
        @include border-radius(50% !important);
        @include box-shadow($shadow);
        position: relative;
        margin: 0 auto;
        -webkit-transform: translateY(50px);
        -ms-transform: translateY(50px);
        transform: translateY(50px);
        @include transition(all 0.4s ease-in-out);
        z-index: 1;
    }
}

.profile-intro {
    & .card-image {
        position: relative;
        width: 100%;
        min-height: 100%;
        @include background('https://via.placeholder.com/1920x1072', center center);
    }
    & .pi-content {
        padding: 70px 40px 24px !important;
    }
    & .pi-footer {
        padding: 0 40px 24px !important;
    }
    & .icon {
        display: inline-block;
        @include transition(all 0.2s ease 0s);
        color: theme-color("primary");
        &:hover,
        &:focus,
        &:active {
            -webkit-transform: scale(1.2);
            -ms-transform: scale(1.2);
            transform: scale(1.2);
        }
    }
    & .icon:not(:last-child) {
        margin-right: 10px;
    }
}

.layer {
    &:before {
        display: block;
        content: "";
        background-color: rgba($black, 0.6);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        z-index: 0;
    }
}

.jr-card {
    @extend %jr-card-style;
    &>.jr-card-body:first-child {
        margin-top: 6px;
    }
    &.p-0>.jr-card-header:first-child,
    &.p-0>.jr-card-body:first-child {
        margin-top: 0;
    }
    & .jr-card-thumb {
        margin: (-$jr-card-padding) (-$jr-card-padding) 0;
    }
    @media screen and (max-width: 575px) {
        margin-bottom: 18px;
    }
    &-full {
        padding: 0;
    }
}

.jr-card-header {
    margin-bottom: $jr-card-margin;
    .card-heading {
        margin-bottom: ($jr-card-margin / 12);
        font-size: 17px;
    }
    &:first-child {
        margin-top: -4px;
    }
    & .badge {
        padding: 2px 5px;
        margin-bottom: 0;
        &:last-child {
            margin-left: 10px;
        }
    }
    @media screen and (max-width: 575px) {
        margin-bottom: 18px;
    }
    & .icon-btn:last-child {
        margin-top: -12px;
        margin-right: -12px;
        -ms-flex-align: start;
        align-self: flex-start;
    }
}

.jr-entry-header {
    margin-bottom: ($jr-card-margin - 10px);
    .entry-heading {
        margin-bottom: ($jr-card-margin / 6);
    }
    .entry-description {
        margin-bottom: 0;
        font-size: 13px;
        color: $gray-600;
    }
    @media screen and (max-width: 575px) {
        margin-bottom: 18px;
    }
}

.card {
    margin-bottom: $jr-card-margin;
    border: 0 none;
    @include border-radius($border-radius);
    @media screen and (max-width: 575px) {
        margin-bottom: 18px;
    }
}

.card-header {
    font-size: 16px;
    .jr-card-intra & {
        border-bottom: 0 none;
        background-color: rgba($black, 0.02);
    }
    & .badge {
        padding: 2px 5px;
        margin-bottom: 0;
        &:last-child {
            margin-left: 10px;
        }
    }
}

.card-title {
    font-size: $h3-font-size;
    text-transform: capitalize;
    .jr-card-metrics & {
        font-size: $h6-font-size;
        text-transform: uppercase;
        margin-bottom: 15px;
    }
    .jr-card-widget & {
        font-size: $h6-font-size;
        text-transform: uppercase;
    }
    @media screen and (max-width: 1366px) {
        font-size: $h4-font-size;
    }
    @media screen and (max-width: 991px) {
        margin-bottom: 10px;
    }
}

.jr-card-intra {
    border: 0 none;
}

.product-item-vertical .card-footer {
    padding-right: 0;
    padding-left: 0;
}

.card-subtitle {
    font-size: 14px;
    color: $gray-700;
    &:not(:last-child) {
        margin-bottom: $h3-font-size;
    }
}

.card-mt-footer {
    padding: 0 10px 14px;
    margin-top: -10px;
}

.jr-card-social {
    font-size: 22px;
    padding-top: 15px;
}

.social-link {
    list-style: none;
    padding-left: 0;
    @include display-flex();
    @include align-items(center);
    margin: 0 -5px 10px;
    & li {
        padding: 0 5px;
        margin-bottom: 5px;
    }
    & li a {
        color: $body-color;
    }
    & li.active a,
    & li a:hover,
    & li a:focus {
        color: $secondary;
    }
    .jr-card-social & {
        margin-bottom: 0;
        @include justify-content(space-between);
    }
    .jr-card-social-around & {
        @include justify-content(space-around);
    }
}

.jr-cart-ab {
    @include display-flex();
    @include align-items(center);
    @include justify-content(space-around);
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    color: $white;
    font-size: $h3-font-size;
    & i {
        vertical-align: middle;
        font-size: 25px;
    }
}

.img-overlay-card {
    @include border-radius($border-radius);
    margin-bottom: $jr-card-margin;
    & .center-crop-img {
        min-height: 110px;
    }
    @media screen and (max-width: 1199px) {
        & .center-crop-img {
            min-height: 135px;
        }
    }
    @media screen and (max-width: 575px) {
        margin-bottom: 18px;
    }
}

.jr-card-overview {
    margin-bottom: $jr-card-margin;
    background-color: $white;
    @include box-shadow($jr-card-shadow);
    position: relative;
    @include border-radius($border-radius-lg);
    padding: $jr-card-padding;
    & .jr-card-title {
        margin-bottom: 20px;
    }
}

.card-img {
    border-radius: $border-radius;
}

.card-img-top {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
}

.card-img-bottom {
    border-bottom-right-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
}

.card-header:first-child {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
}

.card-footer:last-child {
    border-bottom-right-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
}

.jr-full-card {
    padding: 0;
    & .jr-card-header {
        padding: 26px $jr-card-padding;
        margin-bottom: 0;
        margin-top: 0;
    }
    & .table-responsive-material {
        padding-bottom: $jr-card-margin;
    }
}

.jr-weather-card {
    padding: 0;
    & .jr-card-header {
        padding: 40px 20px;
        margin-bottom: 0;
        text-align: center;
        @include background('https://via.placeholder.com/370x250', center center);
        position: relative;
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
        overflow: hidden;
        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            background-color: rgba($black, 0.5);
        }
        & * {
            position: relative;
            z-index: 2;
        }
        & .card-heading {
            font-size: $h1-font-size;
            margin-bottom: 5px;
            @media screen and (max-width: 1366px) {
                font-size: ($h2-font-size + 2);
            }
        }
        & .sub-heading {
            color: $white;
        }
    }
    & .jr-card-body {
        padding: $jr-card-padding;
    }
}