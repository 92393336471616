
body {
    font-family: Futura-PT;
    // font-size: 18px;
    background-color: #C4BDCA;
}

table {
    font-family: Futura-PT !important;
}

.jr-card {
    box-shadow: none !important;
    border-radius: 0 !important;
}
.card {
    box-shadow: none !important;
    border-radius: 0 !important;
}

button {
    font-family: Futura-PT !important;
}
th {
    font-size: 13px !important;
    text-transform: uppercase !important;
}
td {
    font-size: 14px !important;
}
.keune-btn {
    color: $gold !important;
    background-color: transparent !important;
    border: 2px solid $gold !important;
    box-shadow: none !important;
    &:hover {
        background-color: $gold !important;
        color: $white !important;
    }
}

.keune-link {
    color: $gold !important;
    font-weight: 500;
}

.app-container.fixed-drawer .app-main-header {
    background-color: transparent !important;
    background-position: center;
    background-size: cover;
    background-image: url(https://www.keune-akademia.pl/front/assets/footer-bg.png);
}

.app-login-main-content {
    border-radius: 0 !important;
    box-shadow: 0 !important;
}

.keune-title {
    font-size: 22px;
    font-weight: 300;
    margin: 0;
    letter-spacing: 2px;
}
// div[class^="MuiDialo"] {
//     overflow-y: initial !important;
// }
// div[class^="MuiPaper"] {
//     overflow-y: initial !important;
// }
div[class^="MuiDialog"] {
    overflow-y: initial !important;
    overflow-x: hidden;
}
.messages-list {
    ul {
        li {
            font-size: 18px;
            font-weight: 300;
            &.unread {
                background-color: #f4f4f4;
                font-weight: 400;
            }
            .sub-heading {
                font-size: 14px !important;
            }
        }
    }
}
